import React, { useState, useEffect } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const Chat = () => {
  const [isLoading, setIsLoading] = useState(false)
  const textAreaRef = React.useRef(null)
  const chatHistoryRef = React.useRef(null)
  const [question, setQuestion] = useState('')
  const [chatHistory, setChatHistory] = useState([])
  const [chatLog, setChatLog] = useState([])
  const [disableSend, setDisableSend] = useState(false)
  const [user, setUser] = useState('Parent')
  const modelOptions = ['gpt-4-turbo-preview', 'gpt-3.5-turbo-0125']
  const [model, setModel] = useState('gpt-4-turbo-preview')
  const aiCompletion = process.env.REACT_APP_SIMPLE_SERVER + '/chat'
  const getPrompt = process.env.REACT_APP_SIMPLE_SERVER + '/chat/get_prompt'

  const [systemPrompt, setSystemPrompt] = useState()

  useEffect(() => {
    setDefaultPrompt()
  }, [])

  const setDefaultPrompt = () => {
    fetch(getPrompt, {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((data) => {
        setSystemPrompt(data.prompt)
      })
      .catch(() => {
        window.alert('There is an error when fetching the prompt. Please try again later.')
        window.location.reload()
      })
  }

  const handleInputChange = (e) => {
    setQuestion(e.target.value)
  }

  const handleModelChange = (e) => {
    console.log(e.target.value)
    setModel(e.target.value)
  }

  const handleSendMessage = (event) => {
    let question_set
    if (event.key === 'Enter' && !event.shiftKey && disableSend === false) {
      if (user === 'Parent') {
        event.preventDefault()
        setDisableSend(true)
        if (chatHistory.length > 0 && chatHistory[chatHistory.length - 1].role === 'user') {
          setChatHistory((prevChatHistory) => [
            ...prevChatHistory.slice(0, -1),
            { role: 'user', content: prevChatHistory[prevChatHistory.length - 1].content + '\nPA:' + question }
          ])
          question_set = chatHistory[chatHistory.length - 1].content + '\nPA:' + question + '\nEDU:'
        } else {
          setChatHistory((prevChatHistory) => [...prevChatHistory, { role: 'user', content: 'PA:' + question }])
          question_set = 'PA:' + question + '\nEDU:'
        }

        setChatLog((prevChatLog) => [...prevChatLog, { role: 'Parent', content: question }])
        handleAIRuntime(question_set)
        setQuestion('')
        setDisableSend(false)
      } else {
        event.preventDefault()
        setDisableSend(true)

        if (chatHistory.length > 0 && chatHistory[chatHistory.length - 1].role === 'user') {
          setChatHistory((prevChatHistory) => [
            ...prevChatHistory.slice(0, -1),
            { role: 'user', content: prevChatHistory[prevChatHistory.length - 1].content + '\nME:' + question }
          ])
        } else {
          setChatHistory((prevChatHistory) => [...prevChatHistory, { role: 'user', content: 'ME:' + question }])
        }
        setChatLog((prevChatLog) => [...prevChatLog, { role: 'EC', content: question }])
        setQuestion('')
        setDisableSend(false)
      }
    }
  }

  const handleSendButton = (event) => {
    let question_set
    if (disableSend) {
      return
    }
    if (user === 'Parent') {
      event.preventDefault()
      setDisableSend(true)
      if (chatHistory.length > 0 && chatHistory[chatHistory.length - 1].role === 'user') {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory.slice(0, -1),
          { role: 'user', content: prevChatHistory[prevChatHistory.length - 1].content + '\nPA:' + question + '\nEDU:' }
        ])
        question_set = chatHistory[chatHistory.length - 1].content + '\nPA:' + question + '\nEDU:'
      } else {
        setChatHistory((prevChatHistory) => [...prevChatHistory, { role: 'user', content: 'PA:' + question + '\nEDU:' }])
        question_set = 'PA:' + question + '\nEDU:'
      }

      setChatLog((prevChatLog) => [...prevChatLog, { role: 'Parent', content: question }])
      handleAIRuntime(question_set)
      setQuestion('')
      setDisableSend(false)
    } else {
      event.preventDefault()
      setDisableSend(true)

      if (chatHistory.length > 0 && chatHistory[chatHistory.length - 1].role === 'user') {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory.slice(0, -1),
          { role: 'user', content: prevChatHistory[prevChatHistory.length - 1].content + '\nME:' + question }
        ])
      } else {
        setChatHistory((prevChatHistory) => [...prevChatHistory, { role: 'user', content: 'ME:' + question }])
      }
      setChatLog((prevChatLog) => [...prevChatLog, { role: 'EC', content: question }])
      setQuestion('')
      setDisableSend(false)
    }
  }

  const clearHistory = () => {
    if (disableSend) {
      return
    }
    setDefaultPrompt()
    setChatHistory([])
    setChatLog([])
  }

  const switchUser = () => {
    if (disableSend) {
      return
    }
    if (user === 'Parent') {
      setUser('EC')
    } else {
      setUser('Parent')
    }
  }

  const handleAIRuntime = async (question_input = question) => {
    setIsLoading(true)

    let history
    if (chatHistory.length > 0 && chatHistory[chatHistory.length - 1].role === 'user') {
      history = chatHistory.slice(0, -1)
    } else {
      history = chatHistory
    }

    let body = JSON.stringify({
      models: model,
      messages:
        systemPrompt === ''
          ? [...history, { role: 'user', content: question_input }]
          : [...[{ role: 'system', content: systemPrompt }], ...history, { role: 'user', content: question_input }]
    })

    try {
      const response = await fetch(aiCompletion, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: body
      })

      const data = await response.json()
      setIsLoading(false)
      setChatHistory((prevChatHistory) => [...prevChatHistory, { role: 'assistant', content: data.choices[0].message.content }])
      setChatLog((prevChatLog) => [...prevChatLog, { role: 'Assistant', content: data.choices[0].message.content }])
    } catch (error) {
      window.alert('There is an error when fetching the thread. Please try again later.')
      setIsLoading(false)
      setChatHistory((prevChatHistory) => prevChatHistory.slice(0, -1))
      setChatLog((prevChatLog) => prevChatLog.slice(0, -1))
      return
    }
  }

  React.useEffect(() => {
    textAreaRef.current.style.height = '28px'
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px'
  }, [question])

  React.useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight
    }
  }, [chatHistory])

  return (
    <>
      <div className='App'>
        <div id='main' className='main pr-2' ref={chatHistoryRef}>
          <div id='system_input' className='system_input my-3 mb-5 font-black text-[24px] leading-[24px] text-gray-700'>
            Referral Dinner Chat Prototype <br />
          </div>
          <div className='my-6 items-left text-left rounded-lg'>
            <label className='block mb-2 font-black text-[16px] leading-[16px] text-[#F7941E]'>Choose AI model:</label>
            <select
              id='model'
              name='model'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              onChange={handleModelChange}
              value={model}
            >
              {modelOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <hr />
          <div id='chat-history' className='chat-history my-3'>
            <div className='relative w-full py-6'>
              {/*MESSAGE PART*/}
              <ul className='space-y-2'>
                {chatLog.map(function (log, i) {
                  if (log.role === 'Parent')
                    return (
                      <li key={i} className='flex justify-end'>
                        <div>
                          <div className='text-right text-gray-500 text-xs mr-1 mb-1'>{log.role}</div>
                          <div className='relative max-w-md px-4 py-2 text-[16px] text-gray-700 bg-gray-100 rounded shadow text-justify'>
                            <span className='block'>
                              {log.content.split('\n').map(function (text) {
                                return (
                                  <>
                                    {text}
                                    <br />
                                  </>
                                )
                              })}
                            </span>
                          </div>
                        </div>
                      </li>
                    )
                  else
                    return (
                      <li key={i} className='flex justify-start'>
                        <div className='py-4'>
                          <div className='text-left text-gray-500 text-xs ml-1 mb-1'>{log.role}</div>
                          <div className='relative max-w-md text-[16px] text-gray-700 text-left'>
                            <span className='block'>
                              <Markdown className='prose' remarkPlugins={[remarkGfm]}>
                                {log.content}
                              </Markdown>
                            </span>
                          </div>
                        </div>
                      </li>
                    )
                })}
                {isLoading && (
                  <div className='flex justify-center'>
                    <div className='flex flex-col items-center justify-center'>
                      <p className='mb-2 text-gray-500 text-[16px]'>Generating response...</p>
                      <Player
                        autoplay
                        loop
                        src={process.env.PUBLIC_URL + '/rocketgenie.json'}
                        style={{ height: '200px', width: '200px' }}
                      />
                    </div>
                  </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Chat Bar */}
      <div className='fixed bottom-5 left-1/2 transform -translate-x-1/2 w-full'>
        <div className='max-h-[40%] sm:px-0 z-15 w-full mx-auto max-w-[94%] md:max-w-[35rem] xl:max-w-[40rem] opacity-1'>
          <div className='bg-white relative flex h-full w-full cursor-text items-end border border-transparent bg-neutral-25 shadow-input transition-all duration-300 focus-within:border-neutral-400 focus-within:shadow-none hover:border-neutral-400 hover:shadow-none rounded-[30px]'>
            <div className='relative my-1.5 ml-1.5 z-10'>
              <button
                type='button'
                className={`grid h-10 w-16 place-items-center rounded-full transition-colors duration-300 ${
                  user === 'Parent'
                    ? 'bg-gradient-to-r from-orange-400 to-yellow-500 hover:from-yellow-500 hover:to-orange-400'
                    : 'bg-gray-400 hover:bg-gray-500'
                }`}
                onClick={switchUser}
                disabled={isLoading}
              >
                {user}
              </button>
            </div>
            <div className='h-full grow overflow-y-auto py-3 pr-4 lg:py-2.5 2xl:py-[8.5px] pl-2'>
              <textarea
                role='textbox'
                className='t-body-chat block w-full resize-none overflow-y-hidden whitespace-pre-wrap bg-transparent text-primary-700 outline-none placeholder:text-neutral-600'
                spellCheck='false'
                placeholder={user === 'Parent' ? 'Respond as Parent here...' : 'Respond as EC here...'}
                value={question}
                onChange={handleInputChange}
                onKeyDown={handleSendMessage}
                ref={textAreaRef}
                style={{ height: '28px' }}
                disabled={isLoading}
              ></textarea>
            </div>
            <button
              aria-label='Submit text'
              className='flex h-9 w-9 items-center justify-center rounded-full p-1.5 text-neutral-600 bg-neutral-50 my-2 transition-colors duration-300 hover:bg-gradient-to-r hover:from-purple-500 hover:to-red-500'
              type='button'
              disabled={isLoading}
              onClick={clearHistory}
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='13' height='16' viewBox='0 0 448 512' fill='currentColor'>
                <path d='M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z' />
              </svg>
            </button>
            <button
              aria-label='Submit text'
              className='flex h-9 w-9 items-center justify-center rounded-full p-1.5 text-neutral-600 bg-neutral-50 m-2 transition-colors duration-300 hover:bg-gradient-to-r hover:from-yellow-500 hover:to-orange-400'
              type='button'
              disabled={isLoading}
              onClick={handleSendButton}
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='13' height='16' fill='currentColor'>
                <path
                  fillRule='evenodd'
                  d='M.852 7.648a1.2 1.2 0 0 1 0-1.696l4.8-4.8a1.2 1.2 0 0 1 1.696 0l4.8 4.8a1.2 1.2 0 1 1-1.697 1.696L7.7 4.897V14a1.2 1.2 0 0 1-2.4 0V4.897L2.548 7.648a1.2 1.2 0 0 1-1.696 0Z'
                  clipRule='evenodd'
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Chat Bar */}
    </>
  )
}
export default Chat
