import './App.css'
import React, { useState } from 'react'
import PasswordForm from './PasswordForm'

import { Routes, Route } from 'react-router-dom'
import Chat from './pages/chat/chat'

function App() {
  const [passwordEntered, setPasswordEntered] = useState(false)
  const handlePasswordCorrect = () => {
    setPasswordEntered(true)
  }

  return (
    <div className='AppOuterWrapper'>
      <div className='AppInnerWrapper'>
        {passwordEntered || process.env.REACT_APP_ENV == 'local' ? (
          <Routes>
            <Route path='/' element={<Chat />} />
          </Routes>
        ) : (
          <PasswordForm onPasswordCorrect={handlePasswordCorrect} />
        )}
      </div>
    </div>
  )
}

export default App
