// PasswordForm.js
import React, { useState } from 'react'
import PropTypes from 'prop-types'
const PasswordForm = ({ onPasswordCorrect }) => {
  const [password, setPassword] = useState('')

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    // Compare the password entered by the user with the correct password
    if (password === 'geniebook_2') {
      onPasswordCorrect()
    }
  }

  return (
    <>
      <div id='system_input' className='system_input my-3 mb-5 font-black text-[24px] leading-[24px] text-gray-700'>
        Referral Dinner Chat Prototype <br />
      </div>
      <form className='flex mx-auto' onSubmit={handleFormSubmit}>
        <input
          type='password'
          value={password}
          onChange={handlePasswordChange}
          placeholder='Enter password'
          className='w-3/4 p-2 border-2 border-gray-300 rounded-md mr-2'
        />
        <button className='w-2/5 p-2 border-2 border-gray-300 rounded-md hover:bg-gray-200 bg-gray-100 pr-3' type='submit'>
          Submit
        </button>
      </form>
    </>
  )
}

PasswordForm.propTypes = {
  onPasswordCorrect: PropTypes.func.isRequired
}

export default PasswordForm
